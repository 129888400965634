$my-palette: (
  50: #fefefe,
  100: #f5f5f5,
  400: #d6971f,
  500: #ff6b50,
  600: #618baa,
  700: #165582,
  800: #124464,
  900: #010101,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    400: #fff,
    500: #fff,
    600: #fff,
    700: #849c95,
    800: #c6c4c5,
    900: #fff,
  ),
);

@use "@angular/material" as mat;

@include mat.core();

$my-primary: mat.define-palette($my-palette, 800, 700, 900);
$my-accent: mat.define-palette($my-palette, 400, 50, 600);
$my-warn: mat.define-palette($my-palette, 500);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
      warn: $my-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

@include mat.core-theme($my-theme);
@include mat.all-component-themes($my-theme);

.scrollbar {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
    margin: 15px 0;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(201, 203, 207, 1);
    background-color: #102940;
  }
}

.horizontal-scrollbar {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    height: 6px;
    background-color: #f5f5f5;
    margin: 15px 0;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(201, 203, 207, 1);
    background-color: #102940;
  }
}

// general + font

html,
body {
  height: 100%;
  width: 100%;
}
body {
  margin: 0;
  font-family: "Open Sans", sans-serif !important;
}

// classes

.clickable {
  cursor: pointer !important;
}

.bold {
  font-weight: bold !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  overflow: hidden;
}

tr.example-detail-row {
  height: 0 !important;
  transform: translateY(-8px);
}

// tr.example-element-row:not(.example-expanded-row):hover {
//   background: whitesmoke !important;
// }

// tr.example-element-row:not(.example-expanded-row):active {
//   background: #efefef !important;
// }

.example-element-row td {
  border-bottom-width: 0 !important;
}

.example-element-detail {
  overflow: hidden !important;
  display: flex !important;
  flex-direction: row;
  align-items: center;
}
